export const MOUSE_SCROLL = ({ color = '#333', ...props }: React.ComponentPropsWithRef<'svg'>) => (
    <svg
      width="50"
      height="90"
      viewBox="0 0 50 90"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      {/* Mouse Body */}
      <rect
        x="1.5"
        y="1.5"
        width="47"
        height="87"
        rx="24"
        stroke={color}
        strokeWidth="3"
      />
      {/* Scroll Wheel */}
      <circle cx="25" cy="15" r="6" fill={color}>
        <animate
          attributeName="cy"
          from="15"
          to="60"
          dur="2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          from="1"
          to="0"
          dur="2s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
  