import { PlayerCard } from '@/core/blocks';
import { Carousel, CarouselContent, CarouselDots, CarouselItem } from '@/core/ui';
import { EMember, type IPlayer } from '@/types';
import { splitArray } from '@/utils';

export const Squad = ({ players }: { players: IPlayer[] }) => {
  if (players.length < 21) {
    return (
      <div className='grid grid-cols-4 gap-6'>
        {players.map((p) => (
          <PlayerCard
            playerId={p.id}
            type={EMember.SQUAD}
            className='col-span-1'
            key={p.id}
            name={p.name}
            position={p.position}
            image={p.player_image}
            link={p.has_competition_details}
          />
        ))}
      </div>
    );
  }

  const list = splitArray(players, 20);

  return (
    <Carousel
      opts={{
        align: 'start',
        dragFree: true,
      }}
      orientation='vertical'
      className='w-full h-full max-w-[1272px]'
    >
      <CarouselContent className='mt-1 h-[650px] gap-6'>
        {list.map((items, index) => (
          <CarouselItem key={index} className='pt-0 md:basis-1/1 basis-auto'>
            <div className='grid grid-cols-4 gap-6'>
              {items.map((p) => (
                <PlayerCard
                  playerId={p.id}
                  type={EMember.SQUAD}
                  className='col-span-1'
                  key={p.id}
                  name={p.name}
                  position={p.position}
                  image={p.player_image}
                  link={p.has_competition_details}
                />
              ))}
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselDots />
    </Carousel>
  );
};
