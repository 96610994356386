import { envVars } from '@/config';
import type { IGetOnecoreRes } from '@/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchCustomBaseQuery } from '../fetcher';

export const oneCoreApi = createApi({
  reducerPath: 'oneCoreApi',
  baseQuery: fetchCustomBaseQuery({
    baseUrl: envVars.backendUrl,
    mock: false,
  }),
  tagTypes: ['onecore'],
  endpoints: (builder) => ({
    getOneCoreConfig: builder.query<IGetOnecoreRes, void>({
      query: () => ({
        url: '/hall-of-champions/onecore-config',
        isProtected: false,
        forceMock: false,
      }),
    }),
    sendCommand: builder.mutation<
    void,
    { app: string; address?: string; key: string; options?: Record<string, any>; devices?: number[] }
  >({
    query: (body) => ({
      url: 'https://onecore.immera.io/api/webapps/push/command',
      method: 'POST',
      isProtected: false,
      body,
      fullUrl: true,
    }),
  }),
  }),
});

export const { useGetOneCoreConfigQuery, useSendCommandMutation } = oneCoreApi;