import { useEffect, useRef, useState } from 'react';
import { Dialog, DialogClose, DialogContent, DialogTitle, HtmlMarkup } from '@/core/ui';
import { Icon } from '@/core/icon';
import type { IPicture } from '@/types';
import { MOUSE_SCROLL } from '@/core/icon/collections';

export const ScrollingDialog = ({
  open,
  onClose,
  curiosityText,
  curiosityImage,
  title,
}: {
  open: boolean;
  onClose: () => void;
  curiosityText: string;
  curiosityImage: IPicture;
  title: string;
}) => {

  // @ts-ignore
  const [isScrolling, setIsScrolling] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const scrollableRef = useRef<HTMLDivElement | null>(null);
  const fadeInTimeout = useRef<NodeJS.Timeout | null>(null);

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleScroll = () => {
    const container = scrollableRef.current;
    if (!container) return;

    const { scrollTop, clientHeight, scrollHeight } = container;
    const isBottom = scrollTop + clientHeight >= scrollHeight - 10;
    setIsAtBottom(isBottom);

    setIsScrolling(true);
    if (fadeInTimeout.current) clearTimeout(fadeInTimeout.current);
    fadeInTimeout.current = setTimeout(() => setIsScrolling(false), 1000);
  };

  const resetScrollState = async () => {
    await delay(0);
    const container = scrollableRef.current;
    if (container) {
      container.scrollTop = 0;
    }
    setIsScrolling(false);
    setIsAtBottom(false);
  };

  useEffect(() => {
    if (open) {
      resetScrollState();
    }
  }, [open]);

  useEffect(() => {
    const attachListeners = async () => {
      await delay(0);
      const container = scrollableRef.current;
      if (container) {
        container.addEventListener('scroll', handleScroll);
      }
    };

    attachListeners();

    return () => {
      if (scrollableRef.current) {
        scrollableRef.current.removeEventListener('scroll', handleScroll);
      }
      if (fadeInTimeout.current) {
        clearTimeout(fadeInTimeout.current);
        fadeInTimeout.current = null;
      }
    };
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent
        closeJsx={
          <DialogClose onClick={onClose} className='absolute right-8 top-8 rounded-sm bg-transparent'>
            <div className='bg-red-600 rounded-full'>
              <Icon name={'X_ICON'} className='p-2 w-16 h-16' color='#FFF' />
              <span className='sr-only'>Close</span>
            </div>
          </DialogClose>
        }
        className='bg-transparent flex justify-center items-center overflow-hidden shadow-none h-[80%] max-w-[90%] sm:rounded-2xl'
        aria-labelledby='dialog-title'
        aria-describedby='scrolling-dialog-description'
      >
        <div className='bg-[#E6F5F1] bg-trivia flex flex-row justify-between items-stretch rounded-2xl w-full h-full relative'>
          <div className='relative flex flex-col flex-1 mx-28 py-28 w-[600px] space-y-6 pb-28'>
            <DialogTitle className='ml-4 font-united-sans text-[45px] font-bold uppercase leading-[42px] line-[27.6px] tracking-[1.2px]'>
              {title}
            </DialogTitle>
            <div
              className='ml-4 overflow-y-auto mx-auto w-[600px] h-[649px] relative rounded-sm'
              ref={scrollableRef}
            >
              <HtmlMarkup className='relative text-[24px] w-[566px] font-medium leading-[30px] tracking-[1.2px]'>
                {curiosityText}
              </HtmlMarkup>
              <div
                className={`sticky bottom-0 left-0 w-full h-16 bg-gradient-to-b from-transparent via-white/20 to-white/40 pointer-events-none transition-opacity duration-500 ${
                  isAtBottom ? 'opacity-0 invisible' : 'opacity-100 visible'
                }`}
              />
            </div>
            {!isAtBottom && (
              <div className='absolute bottom-16 left-1/2 transform -translate-x-1/2 w-full flex justify-center'>
                <div className='text-xl w-12 h-12 bg-white px-1 py-2 shadow-md rounded-full border-gray-300 border-[1px]'>
                  <MOUSE_SCROLL color='black' className='w-full h-full' />
                </div>
              </div>
            )}
          </div>
          <div className='flex w-[903px] items-center'>
            <img
              src={curiosityImage.path}
              alt={curiosityImage.caption || ''}
              className='w-full h-full object-cover'
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
