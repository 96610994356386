import { useEffect } from 'react';
import { Loading, Maintenance, NotFound, ScreenSaver } from '@/core/blocks';
import { Route, Routes } from 'react-router-dom';
import { ROUTES, envVars } from './config';
import { useIdle } from './core';
import Redirect from './pages';
import CompetitionDetail from './pages/competition/id';
import Home from './pages/home';
import { PrivateRoutes } from './router';

function App() {
  const { globalLoading } = useIdle();

  useEffect(() => {
    const now = new Date();
    const target = new Date();

    target.setHours(9, 0, 0, 0);

    if (now > target) {
      target.setDate(target.getDate() + 1);
    }

    const delay = target.getTime() - now.getTime();

    const timeout = setTimeout(() => {
      window.location.reload();
    }, delay);

    return () => clearTimeout(timeout);
  }, []);

  if (envVars.underMaintenance) {
    return <Maintenance />;
  }

  if (globalLoading) {
    return <Loading />;
  }

  return (
    <>
      <ScreenSaver />
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path={ROUTES.MAIN} Component={Redirect} />
          <Route path={ROUTES.COMPETITION.LIST} Component={Home} />
          <Route path={ROUTES.COMPETITION.VIEW} Component={CompetitionDetail} />
        </Route>
        <Route
          path={ROUTES.LOGIN}
          element={<div className='h-screen flex justify-center items-center text-8xl'>Login</div>}
        />
        <Route path='*' Component={NotFound} />
      </Routes>
    </>
  );
}

export default App;
