import { HtmlMarkup, Typography } from '@/core/ui';
import type { ICompetitionMembers } from '@/types';

export const President = ({
  data,
}: {
  data: ICompetitionMembers['president_info'];
}) => {
  const { president, president_text } = data;
  return (
    <div className='flex flex-row gap-[35px] z-10'>
      {president?.full_body_image?.path ? (
        <img
          src={president?.full_body_image?.path}
          alt={president?.full_body_image?.caption || ''}
          className='ml-2 w-[251px] h-[768px]'
        />
      ) : (
        <img
          src={president?.profile_image?.path}
          alt={president?.full_body_image?.caption || ''}
          className='ml-2 h-1/2 object-contain'
        />
      )}
      <div className='flex flex-col max-w-[386px]'>
        <img src='/gallery/president-logo.svg' alt={'logo'} className='h-auto w-16' />
        <div className='mt-8'>
          <Typography className='uppercase w-full text-chalk text-[80px] leading-[76px] tracking-[4px] font-semibold'>
            {president?.name}
          </Typography>
        </div>
        <div className='flex flex-row py-14'>
          <div className='w-[41px] h-[8.5px] bg-danger' />
          <div className='w-[41px] h-[8.5px] bg-olive' />
        </div>
        <div className='flex flex-col w-full space-y-10'>
          <HtmlMarkup className='text-chalk text-[26px] tracking-[1.3px] leading-[29.9px]'>
            {president_text}
          </HtmlMarkup>
        </div>
      </div>
    </div>
  );
};
