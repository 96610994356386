import { Stack } from '@/core/blocks';
import { Button, HtmlMarkup, Typography } from '@/core/ui';
import type { ICompetitionStats } from '@/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BestScore } from './best-score';
import { GameData } from './game-data';
import { GoalData } from './goal-data';
import { ScrollingDialog } from '@/components/scrollingdialog/scrollingdialog';

export const CompetitionStats = ({
  stats,
  name,
}: {
  stats: ICompetitionStats;
  name: string;
}) => {
  const { t } = useTranslation('translation');
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const isOverflowing = stats.statistic_text.length > 300;

  return (
    <div className='w-full h-screen px-28 bg-content1'>
      <div className='flex flex-col h-full space-y-16'>
        <div className='flex flex-row space-x-11'>
          <div className='flex flex-col space-y-2'>
            <Typography className='text-chalk text-2xl font-medium tracking-[1.5px] leading-[28.5px] -mr-16 mt-[71px] z-10'>
              {name}
            </Typography>
            <Typography className='text-chalk text-3xl font-semibold tracking-[1.5px] leading-[28.5px] mt-[71px]'>
              {t('competition.statistics')}
            </Typography>
          </div>
          <img
            className='h-[480px] w-[940px] object-cover'
            src={stats.statistic_image?.path}
            alt={stats.statistic_image?.caption || ''}
          />
          <div className='flex flex-col justify-end space-y-4 mt-8 items-start'>
            <HtmlMarkup
              className={`text-chalk text-[26px] leading-[29.9px] tracking-[1.3px] max-w-[420px] ${
                isOverflowing ? 'max-h-[300px]' : 'max-h-[250px]'
              } overflow-y-auto pr-14`}
            >
              {stats.statistic_text}
            </HtmlMarkup>
            <Button className='bg-danger rounded-[32px] w-[140px] h-[72px]' onClick={openModal}>
              <Typography className='text-chalk text-2xl tracking-[1.4px] leading-[22px] uppercase'>
                {t('competition.stats.view')} +
              </Typography>
            </Button>
          </div>
        </div>
        <div className='flex flex-row w-full px-24 pt-16 pb-24 bg-graphite/85 rounded-[46px] justify-between'>
          <div className='flex flex-col space-y-12'>
            <GameData gameData={stats.statistic_games} />
          </div>
          <div className='flex items-end'>
            <Stack upperClass='w-[5px] h-[137px]' lowerClass='w-[5px] h-[73px]' />
          </div>
          <div className='flex flex-col space-y-12'>
            <GoalData
              totalGame={
                stats.statistic_games?.defeats + stats.statistic_games?.draws + stats.statistic_games?.wins
              }
              goalData={stats.statistic_goals}
            />
          </div>
          <div className='flex items-end'>
            <Stack upperClass='w-[5px] h-[137px]' lowerClass='w-[5px] h-[73px]' />
          </div>
          <div className='flex flex-col space-y-8'>
            <BestScore goalScorer={stats.statistic_goal_scorers} />
          </div>
        </div>
      </div>
      <ScrollingDialog
        open={open}
        onClose={closeModal}
        curiosityText={stats.curiosity_text}
        curiosityImage={stats.curiosity_image}
        title={`${t('competition.stats.curiosities')} ${name}`}
      />
    </div>
  );
};
